import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {useSelector} from 'react-redux'
import appConfig from '../../configs/appConfig'
import authApi from '../../services/authApi'
import errorToNotification from '../../utils/axios/errorToNotification'
import responseToNotification from '../../utils/axios/responseToNotification'
import thunkAxiosInstance from '../../utils/axios/thunkAxiosInstance'
import {applicationActions} from '../Application/applicationSlice'

type Step = 'sign-in' | 'verify' | 'processing' | 'success' | 'verifyMfa' | 'verifyMfaRecovery' | 'recoverySuccess'

const USERNAME_KEY = `${appConfig.appFullName}:username`
const REMEMBER_ME_KEY = `${appConfig.appFullName}:rememberMe`

interface LoginState {
    username: string,
    password: string,
    rememberMe: boolean,
    verificationCode: string,
    verificationMfaCode: string,
    verificationMfaRecoveryCode: string,

    recaptchaLoaded: boolean,
    captchaToken: string,
    verifyToken: string,
    step: Step,
}

const initialState: LoginState = {
    username: '',
    password: '',
    rememberMe: false,
    verificationCode: '',
    verificationMfaCode: '',
    verificationMfaRecoveryCode:'',

    recaptchaLoaded: false,
    captchaToken: '',
    verifyToken: '',
    step: 'sign-in',
}

const login = createAsyncThunk<void>('login/login', (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const state = selectLoginState(thunk.getState())
    thunk.dispatch(applicationActions.logout())
    // thunk.dispatch(loginActions.setStep('processing'))
    return authApi.login(axiosInstance, {
        username: state.username,
        password: state.password,
        verificationCode: state.verificationCode,
        captchaToken: state.captchaToken,
        verificationMfaCode: state.verificationMfaCode,
        verificationMfaRecoveryCode: state.verificationMfaRecoveryCode,
        loginType: 0
    }).then(response => {
        if (response.headers['token']) {
            thunk.dispatch(loginActions.setStep('success'))
        }
    }).catch(error => {
        thunk.dispatch(loginActions.setStep('sign-in'))
        thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    }).finally(() => {
        thunk.dispatch(loginActions.setVerificationMfaCode(''))
        thunk.dispatch(loginActions.setVerificationMfaRecoveryCode(''))
    })
})

const mfaLogin = createAsyncThunk<void>('login/mfalogin', (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const state = selectLoginState(thunk.getState())
    thunk.dispatch(applicationActions.logout())
    // thunk.dispatch(loginActions.setStep('processing'))
    return authApi.login(axiosInstance, {
        username: state.username,
        password: state.password,
        verificationCode: state.verificationCode,
        captchaToken: state.captchaToken,
        verificationMfaCode: state.verificationMfaCode,
        verificationMfaRecoveryCode: state.verificationMfaRecoveryCode,
        loginType: 1
    }).then(response => {
        if (response.headers['token']) {
            thunk.dispatch(loginActions.setStep('success'))
        }
    }).catch(error => {
        thunk.dispatch(loginActions.setStep('sign-in'))
        thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    }).finally(() => {
        thunk.dispatch(loginActions.setVerificationMfaCode(''))
        thunk.dispatch(loginActions.setVerificationMfaRecoveryCode(''))
    })
})

const recoveryLogin = createAsyncThunk<void>('login/recoveryLogin', (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const state = selectLoginState(thunk.getState())
    thunk.dispatch(applicationActions.logout())
    return authApi.login(axiosInstance, {
        username: state.username,
        password: state.password,
        verificationCode: state.verificationCode,
        captchaToken: state.captchaToken,
        verificationMfaCode: state.verificationMfaCode,
        verificationMfaRecoveryCode: state.verificationMfaRecoveryCode,
        loginType: 2
    }).then(response => {
        if (response.headers['token']) {
            thunk.dispatch(loginActions.setStep('recoverySuccess'))
        }
    }).catch(error => {
        thunk.dispatch(loginActions.setStep('sign-in'))
        thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    }).finally(() => {
        thunk.dispatch(loginActions.setVerificationMfaCode(''))
        thunk.dispatch(loginActions.setVerificationMfaRecoveryCode(''))
    })
})

const refresh = createAsyncThunk<void>('login/refresh', (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const state = selectLoginState(thunk.getState())
    return authApi.refreshVerificationCode(axiosInstance, {
        username: state.username,
    }).then(response => {
        thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
    }).catch(error => {
        thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    }).finally(() => {
        thunk.dispatch(loginActions.setVerificationMfaCode(''))
        thunk.dispatch(loginActions.setVerificationMfaRecoveryCode(''))
    })
})

const remember = createAsyncThunk<void>('login/remember', async (_, thunk) => {
    const state = selectLoginState(thunk.getState())
    if (state.rememberMe) {
        localStorage.setItem(USERNAME_KEY, state.username)
        localStorage.setItem(REMEMBER_ME_KEY, `${state.rememberMe}`)
    } else {
        localStorage.removeItem(USERNAME_KEY)
        localStorage.removeItem(REMEMBER_ME_KEY)
    }
})

const mfaState = createAsyncThunk<void>('login/mfaState', async (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const state = selectLoginState(thunk.getState())
    return authApi.getMfaStateNoAuth(axiosInstance, {
        username: state.username,
    }).then(response => {
        if (response.data.state === 1) { 
            thunk.dispatch(loginActions.setStep('verifyMfa'))
        } else {
            thunk.dispatch(loginActions.setStep('verify'))
        }
    }).catch(error => {
        thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    }).finally(() => {
        thunk.dispatch(loginActions.setVerificationMfaCode(''))
        thunk.dispatch(loginActions.setVerificationMfaRecoveryCode(''))
    })
})


export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        init: (state) => {
            state.username = localStorage.getItem(USERNAME_KEY) || ''
            state.password = ''
            state.rememberMe = localStorage.getItem(REMEMBER_ME_KEY) === 'true'
            state.verificationCode = ''
            state.verificationMfaCode = ''
            state.verificationMfaRecoveryCode = ''

            state.verifyToken = ''
            state.captchaToken = ''
            state.recaptchaLoaded = false
            state.step = 'sign-in'
        },
        destroy: (state) => {
            state.username = ''
            state.password = ''
            state.rememberMe = false
            state.verificationCode = ''
            state.verificationMfaCode = ''
            state.verificationMfaRecoveryCode = ''

            state.verifyToken = ''
            state.captchaToken = ''
            state.recaptchaLoaded = false
            state.step = 'sign-in'
        },
        setUsername: (state, { payload: username }: PayloadAction<string>) => {
            state.username = username
        },
        setPassword: (state, { payload: password }: PayloadAction<string>) => {
            state.password = password
        },
        setRememberMe: (state, { payload: rememberMe }: PayloadAction<boolean>) => {
            state.rememberMe = rememberMe
        },
        toggleRememberMe: (state) => {
            state.rememberMe = !state.rememberMe
        },
        setVerificationCode: (state, { payload: verificationCode }: PayloadAction<string>) => {
            state.verificationCode = verificationCode
        },
        setVerificationMfaCode: (state, { payload: verificationMfaCode }: PayloadAction<string>) => {
            state.verificationMfaCode = verificationMfaCode
        },
        setVerificationMfaRecoveryCode: (state, { payload: verificationMfaRecoveryCode }: PayloadAction<string>) => {
            state.verificationMfaRecoveryCode = verificationMfaRecoveryCode
        },
        setRecaptchaToken: (state, { payload: captchaToken }: PayloadAction<string>) => {
            state.captchaToken = captchaToken
        },
        setRecaptchaLoaded: (state, { payload: recaptchaLoaded }: PayloadAction<boolean>) => {
            state.recaptchaLoaded = recaptchaLoaded
        },
        setVerifyToken: (state, { payload: verifyToken }: PayloadAction<string>) => {
            state.verifyToken = verifyToken
        },
        setStep: (state, { payload: step }: PayloadAction<Step>) => {
            state.step = step
        },
    },
    extraReducers: {
    }
})

export const loginActions = {
    ...loginSlice.actions,
    login,
    mfaLogin,
    recoveryLogin,
    refresh,
    remember,
    mfaState,
}

export function selectLoginState(state: any) {
    return state[appConfig.appFullName][loginSlice.name] as LoginState
}

export function useLoginSelector<R>(selector: (state: LoginState) => R, equalityFn?: (left: R, right: R) => boolean) {
    return useSelector<any, R>(state => selector(selectLoginState(state)), equalityFn)
}