import { combineReducers } from 'redux'
import { applicationSlice } from '../layouts/Application/applicationSlice'
import { forgetPasswordSlice } from "../layouts/ForgetPassword/forgetPasswordSlice"
import { loginSlice } from '../layouts/Login/loginSlice'
import { resetPasswordSlice } from "../layouts/ResetPassword/resetPasswordSlice"
import { resetMFASlice } from "../layouts/ResetMFA/resetMFASlice"

const appReducer = combineReducers({
    [applicationSlice.name]: applicationSlice.reducer,
    [loginSlice.name]: loginSlice.reducer,
    [forgetPasswordSlice.name]: forgetPasswordSlice.reducer,
    [resetPasswordSlice.name]: resetPasswordSlice.reducer,
    [resetMFASlice.name]: resetMFASlice.reducer,
})

export default appReducer