import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Input, makeStyles, Typography } from "@material-ui/core"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { ResetButton } from "./ResetButton"
import { useCallback, useState } from "react"
import { resetMFAActions } from "./resetMFASlice"
import { useNavigate } from "react-router-dom"

export function ResetForm() {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const [username, setUsername] = useState(urlParams.get('username') ?? ""); 
  const [resetMfaDialog, setResetMfaDialog] = useState<boolean>(false)
  const dispatch = useDispatch()
  const resetMFACode = urlParams.get('resetMfaCode') ?? ""
  const styles = useStyles()
  const handleSubmit = () => {
    dispatch(resetMFAActions.init({ username, resetMFACode }));
    setTimeout(() => {
        dispatch(resetMFAActions.resetMFA());
    }, 60);
    setTimeout(() => {
        navigate('/login');
    }, 800);
    
  };
  const handleOpenDialog = () => {
    setResetMfaDialog(true);
  };

  const handleCloseDialog = () => {
      setResetMfaDialog(false);
  };

  const handleConfirmDialog = () => {
      setResetMfaDialog(false);
      handleSubmit();
  };

  return <form className={styles.form} onSubmit={(e) => {
    e.preventDefault();
    handleOpenDialog();
  }}>
    <Typography variant="h5" style={{ textTransform: 'uppercase', lineHeight: '18px' }}>
      <FormattedMessage id="resetMFA.reset" />
    </Typography>
    <Divider className={styles.divider} />
    <UsernameInput username={username} setUsername={setUsername}/>
    <ResetMfaDialog 
        open={resetMfaDialog} 
        onClose={handleCloseDialog} 
        onConfirm={handleConfirmDialog} 
    />
    <ResetDiscription />
    <ResetButton onClick={handleOpenDialog} />
  </form>
}

function UsernameInput({username, setUsername}: {username: string, setUsername: React.Dispatch<React.SetStateAction<string>>}){
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value)
  }, [setUsername])
  const intl = useIntl()
  const styles = useStyles()
  return <div className={styles.root}>
      <Typography variant="body2" color="textSecondary">
          <FormattedMessage id="resetMFA.user" />
      </Typography>
      <Input type="text"
          id="user-text"
          autoComplete="user-text"
          value={username}
          autoFocus
          placeholder={intl.formatMessage({ id: 'resetMFA.user' })}
          onChange={handleChange}
          fullWidth
          disableUnderline
          className={styles.input}
          disabled
      />
  </div>
}

function ResetDiscription() {
  const styles = useStyles()
  return <div className={styles.root}>
      <Typography variant="body2" color="textSecondary">
          <FormattedMessage id="resetMFA.description" />
      </Typography>
  </div>
}

function ResetMfaDialog({open, onClose, onConfirm}:{ open : boolean, onClose: () => void, onConfirm : () => void}) {
  return (
      <Dialog open={open} onClose={onClose} maxWidth="md" style={{flex: 1}} BackdropProps={{style: {backgroundColor: 'rgba(0, 0, 0, 0.2)'}}}>
          <DialogTitle style={{textAlign: 'center', marginTop: 5}}><Typography variant="h3"><FormattedMessage id="resetMFA.dialogTitle" /></Typography></DialogTitle>
          <DialogContent style={{textAlign: 'center'}}>
              <DialogContentText style={{textAlign: 'center'}}>
                  <div style={{marginTop: 10, marginBottom: 20, width: 600}}>
                      <FormattedMessage id="resetMFA.dialogMessage" />
                  </div>
              </DialogContentText>
              <Button
                  variant={'outlined'}
                  color="secondary"
                  onClick={onClose}
                  style={{
                      marginTop: 15,
                      width: '30%',
                      marginRight: 30,
                      marginBottom: 10
                  }}>
                  <FormattedMessage id="cancel" />
              </Button>
              <Button
                  variant={'contained'}
                  color="primary"
                  onClick={onConfirm}
                  style={{
                      marginTop: 15,
                      width: '30%',
                      marginBottom: 10
                  }}>
                  <FormattedMessage id="confirm" />
              </Button>
          </DialogContent>
      </Dialog>
      
  );
}

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    height: 500,
    background: '#F2F1F7',
    borderRadius: 16,
    padding: `${theme.spacing(6)}px ${theme.spacing(5)}px`
  },
  divider: {
    margin: '16px 0 28px',
    background: `linear-gradient(90deg, ${theme.palette.primary.light} 1px, transparent 1px 6px)`,
    backgroundSize: '6px 1px',
  },
  label: {
    marginTop: -4,
    marginBottom: 4,
  },
  root: {
    marginBottom: theme.spacing(1.5)
  },
  input: {
      height: 40,
      padding: theme.spacing(2),
      marginTop: theme.spacing(0.5),
      color: theme.palette.primary.light,
      backgroundColor: theme.palette.grey[300],
  },
}))