import { Route, Routes } from 'react-router-dom'
import { AuthNavigate } from '../components/AuthNavigate/AuthNavigate'
import appConfig from '../configs/appConfig'
import { Code403 } from '../layouts/Code403/Code403'
import { Code404 } from '../layouts/Code404/Code404'
import { ForgetPassword } from '../layouts/ForgetPassword/ForgetPassword'
import { ForgetPasswordCF } from '../layouts/ForgetPassword/ForgetPasswordCF'
import { ForgetPasswordGG } from '../layouts/ForgetPassword/ForgetPasswordGG'
import Login from '../layouts/Login/Login'
import LoginCF from '../layouts/Login/LoginCF'
import LoginGG from '../layouts/Login/LoginGG'
import { NotFound } from "../layouts/NotFound/NotFound"
import { ResetPassword } from "../layouts/ResetPassword/ResetPassword"
import { WhatsNew } from '../layouts/WhatsNew/WhatsNew'
import { Workbench } from '../layouts/Workbench/Workbench'
import { ResetMFA } from '../layouts/ResetMFA/ResetMFA'

enum CaptchaSite {
    GG = "GG",
    CF = "CF"
}

export function AppRoutes() {
    const captchaSite = appConfig.captchaSite
    return <Routes>
        <Route path="/login" element={captchaSite === CaptchaSite.GG ? <LoginGG /> : captchaSite === CaptchaSite.CF ? <LoginCF /> : <Login />} />
        <Route path="/forget-password" element={captchaSite === CaptchaSite.GG ? <ForgetPasswordGG /> : captchaSite === CaptchaSite.CF ? <ForgetPasswordCF /> : <ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-mfa" element={<ResetMFA />} />
        <Route path="/whats-new" element={<WhatsNew />} />
        <Route path="/403" element={<Code403 />} />
        <Route path="/404" element={<Code404 />} />
        <Route index element={<AuthNavigate>
            <Workbench />
        </AuthNavigate>} />
        <Route path="/*" element={<AuthNavigate>
            <Workbench />
            <NotFound scope="base" />
        </AuthNavigate>} />
    </Routes>
}