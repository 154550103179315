import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { resetMFAActions } from "./resetMFASlice"

export function ClearOnMountAndUnmount() {
    const [searchParams] = useSearchParams()
    const username = searchParams.get("username") || ''
    const resetMFACode = searchParams.get("resetMFACode") || ''

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(resetMFAActions.init({ username, resetMFACode }))
        return () => {
            dispatch(resetMFAActions.destroy())
        }
    }, [dispatch, username, resetMFACode])
    return null
}