import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import appConfig from '../../configs/appConfig'
import authApi from '../../services/authApi'
import errorToNotification from '../../utils/axios/errorToNotification'
import responseToNotification from '../../utils/axios/responseToNotification'
import thunkAxiosInstance from '../../utils/axios/thunkAxiosInstance'
import { applicationActions } from '../Application/applicationSlice'

type Step = 'reset' | 'reset-processing' | 'success'

interface ResetMFAState {
    username: string,
    resetMFACode: string,
    step: Step,
}

const initialState: ResetMFAState = {
    username: '',
    resetMFACode: '',
    step: 'reset',
}

const resetMFA = createAsyncThunk<void>('resetMFA/resetMFA', (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const state = selectResetMFAState(thunk.getState())
    thunk.dispatch(resetMFAActions.setStep('reset-processing'))
    return authApi.resetMFA(axiosInstance, {
        username: state.username,
        resetMFACode: state.resetMFACode,
    }).then(response => {
        thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
        thunk.dispatch(resetMFAActions.setStep('success'))
    }).catch(error => {
        thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        thunk.dispatch(resetMFAActions.setStep('reset'))
    })
})

export const resetMFASlice = createSlice({
    name: 'resetMFA',
    initialState,
    reducers: {
        init: (state, action: PayloadAction<{ username: string, resetMFACode: string }>) => {
            const { username, resetMFACode } = action.payload
            state.username = username
            state.resetMFACode = resetMFACode
            state.step = 'reset'
        },
        destroy: (state) => {
            state.username = ''
            state.resetMFACode = ''
            state.step = 'reset'
        },
        setStep: (state, { payload: step }: PayloadAction<Step>) => {
            state.step = step
        },
    },
    extraReducers: {
    }
})

export const resetMFAActions = {
    ...resetMFASlice.actions,
    resetMFA,
}

export function selectResetMFAState(state: any) {
    return state[appConfig.appFullName][resetMFASlice.name] as ResetMFAState
}

export function useResetMFASelector<R>(selector: (state: ResetMFAState) => R, equalityFn?: (left: R, right: R) => boolean) {
    return useSelector<any, R>(state => selector(selectResetMFAState(state)), equalityFn)
}