import {Divider, Grid, IconButton, makeStyles, Typography} from "@material-ui/core"
import {ArrowBack} from "@material-ui/icons"
import {FormattedMessage} from "react-intl"
import {useDispatch} from "react-redux"
import {loginActions} from "./loginSlice"
import {VerificationMfaCodeInput} from "./VerificationMfaCodeInput"
import {VerifyButton} from "./VerifyButton"

export function VerifyMfaForm() {
  const dispatch = useDispatch()
  const styles = useStyles()
  dispatch(loginActions.setVerificationCode(''))
  dispatch(loginActions.setVerificationMfaCode(''))
  dispatch(loginActions.setVerificationMfaRecoveryCode(''))
  const handleLinkClick = () => {
    dispatch(loginActions.setStep('verifyMfaRecovery'));
    dispatch(loginActions.setVerificationMfaCode(''))
    dispatch(loginActions.setVerificationCode(''))
  };
  const handleLinkClickTo2Fa = () => {
    dispatch(loginActions.setStep('verify'));
    dispatch(loginActions.setVerificationMfaCode(''))
    dispatch(loginActions.setVerificationMfaRecoveryCode(''))
  };

  return <form className={styles.form} onSubmit={(e) => {
    dispatch(loginActions.mfaLogin())
    e.preventDefault()
  }}>
    <Grid container alignItems="center">
      <Grid item style={{marginLeft: -16, marginRight: 16}}>
        <IconButton onClick={() => dispatch(loginActions.setStep('sign-in'))}>
          <ArrowBack color="primary" />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography variant="h5" style={{textTransform: 'uppercase', lineHeight: '18px'}}>
          <FormattedMessage id="login.verifyMfa" />
        </Typography>
      </Grid>
    </Grid>
    <Divider className={styles.divider} />
    <Typography variant="body2" color="textSecondary" className={styles.label}>
      <FormattedMessage id="login.verificationMfaCode" />
    </Typography>
    <Grid container alignItems="center" style={{marginTop: 2, marginBottom: 16}}>
      <Grid item xs={8}>
        <VerificationMfaCodeInput />
      </Grid>
    </Grid>
    <div className={styles.helper1}>
      <Grid item xs={8}>
        <FormattedMessage id="login.verifyHavingProblems" />
      </Grid>
    </div>
    <div className={styles.helper1}>
      <Grid item xs={12}>
          <Typography variant="body2" color="secondary" onClick={handleLinkClick}>
            <FormattedMessage id="login.verifyForgetMfaCode" />
          </Typography>
      </Grid>
    </div>
    <div className={styles.helper2}>
      <Grid item xs={12}>
          <Typography variant="body2" color="secondary" onClick={handleLinkClickTo2Fa}>
            <FormattedMessage id="login.changeTo2FaVerify" />
          </Typography>
      </Grid>
    </div>
    <VerifyButton />
    <div style={{marginTop: 20, fontSize: '0.8rem'}}>
      <Grid item xs={12}>
        <FormattedMessage id="login.verifyMfaTips" />
      </Grid>
    </div>

  </form >
}

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    height: 500,
    background: '#F2F1F7',
    borderRadius: 16,
    padding: `${theme.spacing(6)}px ${theme.spacing(5)}px`
  },
  divider: {
    margin: '16px 0 28px',
    background: `linear-gradient(90deg, ${theme.palette.primary.light} 1px, transparent 1px 6px)`,
    backgroundSize: '6px 1px',
  },
  label: {
    marginTop: -4,
    marginBottom: 4,
  },
  input: {
    height: 40,
    padding: theme.spacing(2),
    marginTop: 2,
    marginBottom: theme.spacing(1.5),
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.background.paper,
  },
  helper1: {
    display: 'flex',
    alignItems: 'left',
    marginBottom: theme.spacing(2),
    height: 8,
  },
  helper2: {
    display: 'flex',
    alignItems: 'left',
    marginBottom: theme.spacing(3),
    height: 8,
  },
  link: {
    textDecorationColor: theme.palette.secondary.main,
  },
}))
