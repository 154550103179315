import { makeStyles, Typography } from "@material-ui/core"
import { useEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import pic from './background.png'
import { ClearOnMountAndUnmount } from "./ClearOnMountAndUnmount"
import logo from './logo.svg'
import { RedirectToLogin } from "../ResetPassword/RedirectToLogin"
import { ResetForm } from "./ResetForm"

export function ResetMFA() {  
  
    const intl = useIntl()
    useEffect(() => {
        document.title = intl.formatMessage({id: "resetPassword.reset"}) + " - Brivge"
    }, [intl])

    useEffect(() => {
        const frontLayer = document.querySelector('#webapp-lcbm') as HTMLElement
        frontLayer.style.display = 'none'
        return () => {
            frontLayer.style.display = 'block'
        }
    }, [])

    const styles = useStyles()
    return <>
        <RedirectToLogin />
        <ClearOnMountAndUnmount />
        <div className={styles.root}>
            <div className={styles.content}>
                <div className={styles.left}>
                    <img src={pic} alt="reset password" className={styles.pic} />
                </div>
                <div className={styles.right}>
                    <div className={styles.poweredBy}>
                        <Typography variant="body2" color="textPrimary" style={{ fontFamily: 'ArialLight' }}>
                            <FormattedMessage id="resetPassword.poweredBy" />
                        </Typography>
                        <img src={logo} alt="logo" className={styles.logo} />
                    </div>
                    <ResetForm />
                    <div className={styles.copyright}>
                        <Typography variant="body2" color="textSecondary">
                            <FormattedMessage id="resetPassword.copyright" />
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    </>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        background: 'linear-gradient(-60deg, #D6D6EE 10%, #E9EFFF 60%, #D6D6EE 90%)',
        overflow: 'auto',
    },
    content: {
        width: '100%',
        height: 675,
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    left: {
        flexBasis: 600,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    right: {
        flexBasis: 440,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    pic: {
        width: 760,
        marginLeft: -40,
    },
    poweredBy: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        width: '16%',
        margin: theme.spacing(1.5),
    },
    copyright: {
        marginTop: theme.spacing(3),
    },
}))